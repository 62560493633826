
import Vue from 'vue';
import { Header } from '@/types/DataTable';
import { FilterData } from '@/types/Filters';
import { getUserSettingByKey } from '@/utils/settings';
import { empty, each } from '@/utils/helpers';
import Http, { HttpError } from '@/utils/Http';
import intl from '@/utils/intl';

import Filters from '@/components/filters.vue';
import Pagination from '@/components/pagination.vue';
import ConfirmDialog from '@/components/dialogs/confirm.vue';
import InvoiceFiles from '@/components/invoices/files-sheet.vue';
import InvoicesExportModal from '@/components/invoices/export-modal.vue';
import InvoicesLogsModal from '@/components/invoices/logs-modal.vue';
import ImportFileModal from '@/components/invoices/import-file-modal.vue';

export default Vue.extend({
  name: 'InvoicesModule',
  props: {
    title: String
  },
  components: {
    Filters,
    Pagination,
    ConfirmDialog,
    InvoiceFiles,
    InvoicesExportModal,
    InvoicesLogsModal,
    ImportFileModal
  },
  data() {
    return {
      filtersMenu: false,
      filtersWidth: 'auto',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalRows: 0,
      totalItems: 0,
      filters: {},
      filtersInputs: [] as Array<FilterData>,
      headers: [] as Array<Header>,
      type: 'ii',
      allTypes: {
        ii: intl.t('views.invoices.tabs.issuedInvoices'),
        ri: intl.t('views.invoices.tabs.receivedInvoices'),
        rm: intl.t('views.invoices.tabs.receivedMessages')
      } as Record<string, string>,
      resendInvoiceLoading: false,
      invoicesLoading: false,
      invoices: [] as Array<Record<string, string>>,
      invoicesSelected: [] as Array<Record<string, string>>,
      statuses: [
        'not_processed',
        'processing',
        'error',
        'processed_ok'
      ] as Array<string>,
      filesSheet: {
        status: false,
        title: '',
        invoice_id: 0,
        invoice_number: ''
      },
      editModal: {
        status: false,
        loading: false,
        title: '',
        text: '',
        invoice_id: 0,
        invoice_number: ''
      },
      logsModal: {
        status: false,
        id: 0,
        type: 'ii',
        title: ''
      }
    }
  },
  computed: {
    pageTitle(): string {
      return empty(this.title) ? '' : intl.t(this.title);
    },
    filtersButton(): string {
      return intl.t('buttons.filters');
    },
    editButton(): string {
      return intl.t('buttons.edit');
    },
    saveButton(): string {
      return intl.t('buttons.save');
    },
    exportButton(): string {
      return intl.t('buttons.export');
    },
    importButton(): string {
      return intl.t('buttons.import');
    },
    downloadButton(): string {
      return intl.t('buttons.download');
    },
    cancelButton(): string {
      return intl.t('cancel');
    },
    associatedFilesMenuItem(): string {
      return intl.t('views.invoices.associatedFilesMenuItem');
    },
    logsMenuItem(): string {
      return intl.t('views.invoices.logsMenuItem');
    },
    resendTooltip(): string {
      return intl.t('views.invoices.resend.tooltip');
    },
    statusesOptions(): Array<Record<string, string>> {
      const result: Array<Record<string, string>> = [];
      each(this.statuses, (status: string) => {
        result.push({
          id: status,
          name: intl.t(`views.invoices.statuses.${status}`)
        });
      })
      return result;
    },
    canDownload(): boolean {
      return Boolean(getUserSettingByKey('CanDownload'));
    },
    canImport(): boolean {
      return Boolean(getUserSettingByKey('EnableIssuedInvoicesImport')) && this.type === 'ii';
    }
  },
  watch: {
    async page(value: number): Promise<void> {
      if (value != 0) {
        await this.getInvoices();
      }
    }
  },
  methods: {
    needToResubmit(item: Record<string, string>): boolean {
      return Boolean(getUserSettingByKey('EnableResetInvoiceStatus'))
        && ['validation_error', 'xml_business_errors'].includes(item.status);
    },
    pageChange(val: number): void {
      this.page = val;
    },
    itemsPerPageChange(val: number): void {
      this.itemsPerPage = val;
      this.page = 0;
      this.$nextTick(() => this.page = 1);
    },
    submitFilters(filters: Record<string, string>): void {
      let data = {} as Record<string, string>;
      each(filters, (val: string, key: string) => {
        data[`filters[${key}]`] = val;
      });
      this.filters = data;
      this.page = 0;
      this.$nextTick(() => this.page = 1);
    },
    resetFilters(filters: Record<string, string>): void {
      this.filters = filters;
      this.page = 0;
      this.$nextTick(() => this.page = 1);
    },
    setLabelName(name: string): string {
      return intl.t(`views.invoices.${name}Label`);
    },
    filtersInput(status: boolean) {
      if (status) {
        const pageContainer = this.$refs.pageContainer as HTMLDivElement;
        if (pageContainer) {
          this.filtersWidth = String(pageContainer.offsetWidth - 24);
        }
      }
    },
    setHeaders(): void {
      this.headers = [];
      let headers: Array<Header> = [];
      switch (this.type) {
        case 'ii':
          headers = [
            {
              text: 'ID',
              value: 'invoice_id',
              hidden: true
            }, {
              text: '',
              value: 'icons'
            }, {
              text: this.setLabelName('invoiceNumber'),
              value: 'invoice_number',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('issueDate'),
              value: 'issue_date',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('dueDate'),
              value: 'due_date',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('issuerName'),
              value: 'issuer_name',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('issuerVatNumber'),
              value: 'issuer_vat_number',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('recipientName'),
              value: 'recipient_name',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('recipientVatNumber'),
              value: 'recipient_vat_number',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('idUpload'),
              value: 'id_upload',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('idDownload'),
              value: 'id_download',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('status'),
              value: 'status',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('valueWithoutVat'),
              value: 'value_without_vat',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('valueVat'),
              value: 'value_vat',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('valueWithVat'),
              value: 'value_with_vat',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('lastUpdate'),
              value: 'last_update',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('errorMessage'),
              value: 'error_message',
              hidden: true
            }, {
              text: '',
              value: 'actions',
              align: 'end',
              class: 'v-custom-data-table--sticky-col',
              cellClass: 'v-custom-data-table--sticky-col'
            }
          ];
          break;
        case 'ri':
          headers = [
            {
              text: 'ID',
              value: 'invoice_id',
              hidden: true
            }, {
              text: '',
              value: 'icons'
            }, {
              text: this.setLabelName('invoiceNumber'),
              value: 'invoice_number',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('issueDate'),
              value: 'issue_date',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('dueDate'),
              value: 'due_date',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('issuerName'),
              value: 'issuer_name',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('issuerVatNumber'),
              value: 'issuer_vat_number',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('recipientName'),
              value: 'recipient_name',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('recipientVatNumber'),
              value: 'recipient_vat_number',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('idDownload'),
              value: 'id_download',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, /*{
              text: this.setLabelName('status'),
              value: 'status',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            },*/ {
              text: this.setLabelName('valueWithoutVat'),
              value: 'value_without_vat',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('valueVat'),
              value: 'value_vat',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('valueWithVat'),
              value: 'value_with_vat',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('lastUpdate'),
              value: 'last_update',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: '',
              value: 'actions',
              align: 'end',
              class: 'v-custom-data-table--sticky-col',
              cellClass: 'v-custom-data-table--sticky-col'
            }
          ];
          break;
        case 'rm':
          headers = [
            {
              text: 'ID',
              value: 'id',
              hidden: true,
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('type'),
              value: 'type',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }, {
              text: this.setLabelName('details'),
              value: 'details'
            }, {
              text: this.setLabelName('message'),
              value: 'message'
            }, {
              text: this.setLabelName('date'),
              value: 'creation_date',
              class: 'text-no-wrap',
              cellClass: 'text-no-wrap'
            }
          ];
          break;
      }
      this.headers = headers;
    },
    setFilters(): void {
      this.filtersInputs = [];
      let filters: Array<FilterData> = [];
      switch (this.type) {
        case 'ii':
          filters = [
            {
              name: 'invoice_no',
              type: 'number',
              label: this.setLabelName('invoiceNumber')
            }, {
              name: 'issue_date',
              type: 'date_between',
              label: this.setLabelName('issueDate')
            }, {
              name: 'due_date',
              type: 'date_between',
              label: this.setLabelName('dueDate')
            }, {
              name: 'vat_number_issuer',
              type: 'number',
              label: this.setLabelName('issuerVatNumber')
            }, {
              name: 'vat_number_recipient',
              type: 'number',
              label: this.setLabelName('recipientVatNumber')
            }, {
              name: 'company_name_recipient',
              type: 'text',
              label: this.setLabelName('recipientCompanyName')
            }, {
              name: 'id_upload',
              type: 'number',
              label: this.setLabelName('idUpload')
            }, {
              name: 'id_download',
              type: 'number',
              label: this.setLabelName('idDownload')
            }, {
              name: 'status',
              type: 'select',
              options: this.statusesOptions,
              text: 'name',
              value: 'id',
              label: this.setLabelName('status')
            }, {
              name: 'last_update',
              type: 'date_between',
              label: this.setLabelName('lastUpdate'),

            }
          ];
          break;
        case 'ri':
          filters = [
            {
              name: 'invoice_no',
              type: 'number',
              label: this.setLabelName('invoiceNumber')
            }, {
              name: 'issue_date',
              type: 'date_between',
              label: this.setLabelName('issueDate')
            }, {
              name: 'due_date',
              type: 'date_between',
              label: this.setLabelName('dueDate')
            }, {
              name: 'vat_number_issuer',
              type: 'number',
              label: this.setLabelName('issuerVatNumber')
            }, {
              name: 'company_name_issuer',
              type: 'text',
              label: this.setLabelName('issuerCompanyName')
            }, {
              name: 'vat_number_recipient',
              type: 'number',
              label: this.setLabelName('recipientVatNumber')
            }, {
              name: 'id_download',
              type: 'number',
              label: this.setLabelName('idDownload')
            }, {
              name: 'last_update',
              type: 'date_between',
              label: this.setLabelName('lastUpdate')
            }
          ];
          break;
        case 'rm':
          filters = [
            {
              name: 'id',
              type: 'number',
              label: 'ID'
            }, {
              name: 'id_request',
              type: 'number',
              label: this.setLabelName('idRequest')
            }, {
              name: 'date',
              type: 'date_between',
              label: this.setLabelName('date')
            }
          ];
          break;
      }
      this.filtersInputs = filters;
    },
    async getInvoices(resetInvoices = true): Promise<void> {
      this.invoicesLoading = true;
      if (resetInvoices) {
        this.invoicesSelected = [];
        this.invoices = [];
      }
      try {
        const response = await (new Http()).get(`/v1/invoices/${this.type}`, {
          params: {
            page: this.page,
            per_page: this.itemsPerPage,
            ...this.filters
          }
        });
        if (!empty(response.data)) {
          this.invoices = response.data.data;
          this.pageCount = Number(response.data.last_page);
          this.totalRows = Number(response.data.total);
          this.totalItems = Number(response.data.total_items);
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.invoicesLoading = false;
    },
    async refreshRow(id: string): Promise<void> {
      try {
        const response = await (new Http()).get(`/v1/invoices/${this.type}/${id}`);
        if (!empty(response.data)) {
          const data = response.data;
          delete data['invoice_json'];
          const row  = this.invoices.findIndex((inv: Record<string, string>) => inv.invoice_id == id);
          if (row > -1) {
            this.invoices.splice(row, 1, data);
          }
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
    },
    async setType(type: string): Promise<void> {
      this.type = type;
      this.pageCount = 0;
      this.page = 1;
      this.filters = {};
      this.setHeaders();
      this.setFilters();
      await this.getInvoices();
    },
    async saveInvoice(): Promise<void> {
      this.editModal.loading = true;
      try {
        const response = await (new Http()).post(`/v1/invoices/${this.type}/${this.editModal.invoice_id}`, JSON.stringify({ json: this.editModal.text }));
        if (!empty(response.data)) {
          await this.refreshRow(String(this.editModal.invoice_id));
          this.$root.$children[0].$children[0].setFlash('success', intl.t('views.invoices.savedText', this.editModal.invoice_number));
          this.closeEditModal();
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.editModal.loading = false;
    },
    async resendInvoice(item: Record<string, unknown>): Promise<void> {
      if (
        await (this.$refs.ConfirmDialog as InstanceType<typeof ConfirmDialog>).open(
          intl.t('views.invoices.resend.confirmTitle'),
          intl.t('views.invoices.resend.confirmText'),
          {
            confirmBtn: intl.t('buttons.send')
          }
        )
      ) {
        this.resendInvoiceLoading = true;
        try {
          const response = await (new Http()).put(`/v1/invoices/${this.type}/${item.invoice_id}`);
          if (!empty(response.data)) {
            await this.refreshRow(String(item.invoice_id));
          }
        } catch (error) {
          const err = (error as HttpError).response?.data.error;
          this.$root.$children[0].$children[0].setFlash('error', err.message);
        }
        this.resendInvoiceLoading = false;
      }
    },
    async downloadInvoice(): Promise<void> {
      const item = this.invoicesSelected[0];
      try {
        const response = await (new Http()).post(`/v1/invoices/${this.type}/${item.invoice_id}/download`, null, {
          responseType: 'blob'
        });
        if (response.data.size) {
          let blob = new Blob([response.data], { type: response.data.type });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = item.invoice_number + '.zip';
          link.click();
        }
      } catch (error) {
        const err = (error as HttpError).response?.data;
        const blob = new Blob([err], { type: err.type });
        const responseError = await blob.text();
        const errorResponse = JSON.parse(responseError);
        this.$root.$children[0].$children[0].setFlash('error', errorResponse.error.message);
      }
    },
    async openEditModal(item: Record<string, string>): Promise<void> {
      this.editModal.title = `${this.editButton} ${item.invoice_number}`;
      this.editModal.text = '';
      this.editModal.invoice_id = Number(item.invoice_id);
      this.editModal.invoice_number = item.invoice_number;
      this.editModal.status = true;
      this.editModal.loading = true;
      try {
        const response = await (new Http()).get(`/v1/invoices/${this.type}/${item.invoice_id}`);
        if (!empty(response.data)) {
          this.editModal.text = response.data['invoice_json'];
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.editModal.loading = false;
    },
    closeEditModal(): void {
      this.editModal.title = '';
      this.editModal.text = '';
      this.editModal.invoice_id = 0;
      this.editModal.invoice_number = '';
      this.editModal.loading = false;
      this.editModal.status = false;
    },
    openFilesSheet(item: Record<string, string>): void {
      this.filesSheet.title = intl.t('views.invoices.filesTitle', item.invoice_number);
      this.filesSheet.invoice_id = Number(item.invoice_id);
      this.editModal.invoice_number = item.invoice_number;
      this.filesSheet.status = true;
    },
    closeFilesSheet(): void {
      this.filesSheet.title = '';
      this.filesSheet.invoice_id = 0;
      this.editModal.invoice_number = '';
      this.filesSheet.status = false;
    },
    openLogsModal(item: Record<string, string>): void {
      this.logsModal.title = intl.t('views.invoices.logs.title', item.invoice_number);
      this.logsModal.type = this.type;
      this.logsModal.id = Number(item.invoice_id);
      this.logsModal.status = true;
    }
  },
  async mounted() {
    this.setHeaders();
    this.setFilters();
    await this.getInvoices();
  }
});
